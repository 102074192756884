import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Post from "../components/post"

export default class DateList extends React.Component {
  render() {
    const { date } = this.props.pageContext
    const posts = this.props.data.allMarkdownRemark.edges
    return (
      <Layout>
        <SEO title={`blog | ${date}`} />
        {posts.map(({ node }) => {
          return (
            <Post key={node.frontmatter.slug} node={node} summaryView={true} />
          )
        })}
      </Layout>
    )
  }
}

export const dateListQuery = graphql`
  query dateListQuery($lt: Date!, $gt: Date!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {date: {gt: $gt, lt: $lt}, templateKey: {eq: null}}}
    ) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            date
            title
            slug
            tags
          }
        }
      }
    }
  }
`